.ant-table {
    background: none !important;
    font-weight: 500;
}
  
.ant-table table {
    border-spacing: 0 8px;
}

.ant-table table .ant-checkbox-wrapper{
    border-spacing: 0px;
}
  
.ant-table-thead >tr >th{
    background: none !important;
    text-align: center;
}
  
.ant-table-tbody > tr > td {
    padding: 10px !important
}
  
.ant-table-row {
    background-color: #FFFFFF;
    text-align: center;
}

.user {
    padding: 8px;
    background-color: #EBEDEF;
}
  
.row-class-name-pantient {
    background-color: #FFFFFF;
    border-radius: 6px;
}
  
.row-class-name-pantient td:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
  
.row-class-name-pantient td:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.row-create-at {
    border: 1px solid black;
    border-radius: 10px;
    padding: 10px 20px;
}

.row-kana-name {
    font-size: 20px;
    font-weight: 600;
}

@media only screen and (max-width: 1000px) {
    * {
        font-size: 10px;
    }
    h1 {
        font-size: 15px;
    }
    .patient-text {
        font-size: 15px;
    }
    .ant-table-cell{
        font-size: 10px;
        padding: 0px;
        width: auto;
    }
    .row-kana-name {
        font-size: 15px;
        font-weight: 600;
    }
}
  
.ant-slider-track {
    background-color: #0298DC
}
  
.ant-slider-dot {
    background-color: #293C4F;
    border: none;
} 
.ant-slider-dot::after {
    margin-bottom: 20px;
    content: '';
}
  
.ant-slider-dot-active {
    background-color: #0298DC;
}

.csv-swapper {
    font-weight: 600;
    display: grid;
    align-items: center;
    background-color: #FFFFFF;
    padding: 20px 40px 60px 40px;
    color: #293C4F;
    flex: 1;
}

.ant-upload {
    background-color: '#B8BFC7';
}

.ant-upload-select{
    background: none;
}

.csv-col {
    border: 1px dashed #B8BFC7;
    text-align: center
}

.csv-submit {
    background-color: #0298DC;
    padding: 0 60px 0 20px;
    margin-top: 30px;
    border-radius: 6px;
    box-shadow: 0px 0px 8px #293C4F29
}

.btn-upload {
    font-size: 16px;
    font-weight: bold;
    border-radius: 32px;
    background-color: #DC4602;
    border-color: #DC4602;
    padding: 5px 20px;
    height: auto
}

.btn-upload:hover {
    background-color: #FFFFFF;
    border-color: #FFFFFF;
    color: #DC4602;
}

.ant-table-placeholder {
    display: none;
}

.normal-button {
    font-size: 16px;
    border-radius: 32px;
    background-color: #0298DC;
    padding: 5px 20px;
    height: auto
}

.bnt-download {
    font-size: 16px;
    border-radius: 32px;
    background-color: #0298DC;
    padding: 5px 20px;
    height: auto
}

.bnt-download:hover {
    background-color: #FFFFFF;
    color: #0298DC;
    border-color: #FFFFFF;
}

.btn-assign {
    font-size: 16px;
    border-radius: 32px;
    background-color: #73dc02;
    border-color: #73dc02;
    padding: 5px 20px;
    height: auto
}

.btn-assign:hover {
    background-color: #FFFFFF;
    color: #73dc02;
    border-color: #73dc02;
}

.small-button {
    border-radius: 16px;
    background-color: #0298DC;
    padding: 5px 10px;
    height: auto
}

.ant-pagination-item-active {
    border: none;

}
.ant-pagination-item-active:hover {
    color: #FFFFFF;
}

.ant-pagination-item-active a{
    background-color: #293C4F;
    border-radius: 5px;
    color: #FFFFFF;
}

.ant-pagination-item {
    background-color: #FFFFFF;
    border-radius: 5px;
    border-color: none;
    color: #FFFFFF;
}

.ant-pagination-item:hover{
    border-color: none;
}

.ant-pagination-item-active a:hover {
    color: #FFFFFF;
}

.ant-table-pagination-right{
    justify-content: flex-start;
}

.ant-pagination-prev .ant-pagination-item-link {
    border: none !important;
    background: none !important;
}
.ant-pagination-next .ant-pagination-item-link {
    border: none !important;
    background: none !important;
}

.ant-table-column-sorter {
    width: 20px;
    background-color: white;
    border-radius: 20px;
    color: black;
}

.footer {
    background-color: '#E2E7ED';
}

.ant-slider-tooltip {
    visibility: hidden;
}