  .highcharts-plot-border {
    stroke-width: 2px;
    stroke: #293C4F;
  }

  .highcharts-credits {
    visibility: hidden;
  }

  .highcharts-grid-line{
    display: none;
  }

  .highcharts-axis-line {
    display: none;
  }

  .highcharts-scatter-series{
    overflow: hidden;
  }

  .highcharts-title {
    font-weight: 600;
  }

  .highcharts-axis-labels text {
    font-weight: 600;
  }

  .highcharts-axis-title{
    font-weight: 600;
  }

  .highcharts-warning {
    color: red;
    display: grid; 
    align-items: center;
    text-align: center;
  }

  .highcharts-xaxis-labels text{
    transform: none;
  }