.inputEven {
    padding: 5px 20px;
    align-items: center;
    
}
.inputOdd  {
    padding: 5px 20px;
}
  
.input-both:focus {
    box-shadow: none;
}
  
.input-item .ant-form-item-label {
    background-color: #EBEDEF;
}
  
.input-item .ant-input:placeholder-shown{
    background-color: #EBEDEF;
    border: none;
}
  
.input-item .ant-form-item-control {
    background-color: #EBEDEF;
  
}

.input-item .ant-form-item-control-input-content {
    padding: 10px 20px;
}

.input-item .ant-form-item-label-wrap{
    padding: 10px 0px;
}

.patient-birth {
    background-color: transparent;
    width: 100%;
    border: none;
}

.patient-detail {
    border-bottom: 1px solid #ced3d9;
    color: #293C4F;
}

.patient-tags {
    font-size: 20px;
    font-weight: 700;
}

.patient-slider-date {
    padding: 8px 15px; 
    color: #FFFFFF; 
    border-Radius: 5px; 
}

.patient-img {
    background-color: #edeef2;
    color: #8f9297;
    padding: 20px;
}

.patient-insulin{
    border-bottom: 1px solid #ced3d9;
}

.patient-tri-shape{
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}

.center {
    text-align: center;
}
.patient-col {
    text-align: center;
    color: #0298DC;
}

.patient-insulin-button {
    border: 2px solid #0298DC;
    color: #0298DC;
    background-color: #E2E7ED;
}

.patient-text {
    font-size: 25px;
}

.insulin-history {
    background-color: #FFFFFF; 
    width: 100%; 
    border-radius: 10px; 
    padding: 10px 20px; 
    margin-bottom: 5px;
    font-weight: normal;
}

.insulin-history span{
    padding-left: 15px;
}

.insulin-history div{
    font-weight: bold;
}

.patient-insulin-input {
    border-radius: 22px;
    width: 90%;
}

.patient-insulin-dose{
    font-size: 25px;
}

.patient-record-button {
    font-weight: 600;
    font-size: 16px;
    border-radius: 32px;
    background-color: #0298DC;
    padding: 10px 40px;
    height: auto
}

.edit-patient-title {
    margin-bottom: 20px;
    font-weight: 600;
    align-items: center;
}

.edit-patient-submit {
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    border-radius: 32px;
    background-color: #0298DC;
    padding: 10px;
    height: auto
}

.patient-title{
    padding-bottom: 20px;
}

.edit-patient-back {
    width: 100%; 
    background: none; 
    border: none; 
    font-weight: 600; 
    color: #0298DC; 
    padding-top: 20px;
    box-shadow: none;
}

.ant-input-status-success {
    font-weight: bold;
}
.edit-patient-back:hover {
    background: none; 
    border: none; 
}

.ant-input-status-success {
    background: none;
    border: none;
}

.insulin-modal {
  justify-content: center;
  text-align: center;
  font-weight: bold;
}

.insulin-modal .ant-modal-body {
    padding: 50px;
    font-size: 22px;
}

.insulin-modal .ant-modal-content {
    border-radius: 8px;
    box-shadow: 0 0 8px #293C4F4D;
    overflow: hidden;
}

.insulin-modal .ant-modal-footer {
    height: 50px;
    border: 1px solid #293C4F4D;
    padding: 0;
}

.insulin-modal button {
    width: 50%;
    height: 100%;
    border: none;
    margin: 0 !important;
}

.insulin-modal button span {
    color: #0298dc;
    font-size: larger;
}

.insulin-modal .ant-modal-close {
    visibility: hidden;
}

.button-note {
    float: right; 
    font-weight: 500; 
    border-radius: 20px; 
    border: 1px solid black; 
    padding: 0px 20px; 
    color: black;
    background-color: transparent;
}

.button-edit {
    border-radius: 20px;
    border: 1px solid black;
    background-color: transparent;
}

.button-edit:hover{
    color: #40a9ff;
    background-color: #FFFFFF;
}

.edit-img {
    padding-bottom: 2px;
}


.button-edit .ant-space-item {
    padding: none;
}

.ant-input-disabled {
    background-color: transparent !important;
}

.ant-select-selector{
    background-color: transparent !important;
    border: none !important;
}

.ant-select-selection-placeholder{
    font-weight: normal;
}

.special-form * {
    font-weight: 600;
}