.login-row {
    height: 100vh;
    align-items: center;
    display: flex; 
    padding-top: 80px;
    font-weight: 600
}

.login-title {
    text-align: center; 
    padding-bottom: 50px; 
    color: #00aaf0
}

.login-title > h1 {
    color: #00aaf0;
    font-weight: 600;
}

.login-title > p {
    color: #231815;
    font-weight: 600;
}

.login-forgot-password {
    text-align: center;
    text-decoration: underline;
    padding-bottom: 16px
}

.login-register {
    padding-top: 150px; 
    text-align: center;
    text-decoration: underline;
    padding-bottom: 80px
}

.register-row {
    align-items: center;
    display: flex; 
    font-weight: 600;
    padding-top: 80px;
    height: 100vh;
}

.register-input {
    padding: 10px;
    margin-top: 5px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0px 0px 8px #293C4F29;
}

.register-submit {
    padding: 10px 40px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0px 0px 8px #293C4F29;
    background-color: #0298DC;
}

.reset-password {
    display: flex; 
    font-weight: 600;
    padding-top: 80px;
    height: 90vh;
}

.reset-password h1 {
    text-align: center;
}

.reset-button {
    width: 100%;
    border-radius: 8px;
    background-color: #0298DC;
    box-shadow: 0px 0px 8px #293C4F29;
    padding: 10px 40px;
    height: auto;
}

.ant-form-item-explain-error{
    color: #DC4602;
}

.ant-input-affix-wrapper-status-error{
    color: #DC4602;
    box-shadow: 0px 0px 8px #293C4F29;
}

.login-forgot-password a {
    color: #293C4F;
}

.login-register a{
    color: #293C4F;
}